import React from "react";
import maintenance from "../assets/img/Server-Maintenance.svg";
// Sections

export default function Valori() {
    return (
        <div style={{paddingTop: "80px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img style={{
                maxWidth: "100%",
                paddingTop: "150px",
                paddingBottom: "250px",
            }} src={maintenance} alt={"mentenanta"}/>
        </div>
    );
}


