import * as React from 'react';
// import LinearProgress from '@mui/joy/LinearProgress';
// import Typography from '@mui/joy/Typography';
import { useCountUp } from 'use-count-up';
// import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses, LinearProgressProps } from '@mui/material/LinearProgress';

interface State {
    valueBar?: number;
}

export default function LinearProgressBar(props:State) {
    const { valueBar } = props;
    const { value } = useCountUp({
        isCounting: true,
        duration: 2,
        easing: 'linear',
        start: 0,
        end: valueBar,
        onComplete: () => ({
            shouldRepeat: false,
            delay: 2,
        }),
    });

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? 'rgb(242, 179, 0)' : '#308fe8',
        },
    }));

    return (
        <Box sx={{ width: '100%' }}>
            <div className={'d-flex align-items-center'}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <BorderLinearProgress className variant="determinate" value={`${Math.round(Number(value))}`} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <h6 variant="body2" color="text.secondary">{`${Math.round(Number(value))}%`}</h6>
                </Box>
            </div>
        </Box>
    );
}