import React, {createContext, useRef, useState} from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Screens
import Landing from "./screens/Landing.jsx";
import { BrowserRouter, Routes, Switch, Route } from "react-router-dom";
import TopNavbar from "./components/Nav/TopNavbar";
import Viziune from "./pages/Viziune";
import Resurse from "./pages/Resurse";
import DespreAutism from "./pages/DespreAutism";
import Doneaza from "./pages/Doneaza";
import Footer from "./components/Sections/Footer";
import Misiune from "./pages/Misiune";
import Valori from "./pages/Valori";
import Echipa from "./pages/Echipa";
import DoneazaOnline from "./pages/DoneazaOnline";
import ListaCampanii from "./pages/ListaCampanii/ListaCampanii";
import ModalBase from "./components/ModalBase/ModalBase";

export const IsVisibleContext = createContext(null);
export const refSections = createContext();

const theme = createTheme({
    palette: {
        ochre: {
            main: '#26a884',
            light: '#29af8a',
            dark: '#A29415',
            contrastText: '#1e8a6c',
        },
    },
});

export default function App() {

    const aboutSection = useRef();
    const contactSection = useRef();
    const homeSection = useRef();
    const projectsSection = useRef();
    const [isVisibleHeader, setIsVisibleHeader] = useState(false);
    const [refSectionsList, setRefSectionsList] = useState({
        aboutSection: aboutSection,
        homeSection: homeSection,
        contactSection: contactSection,
        projectsSection: projectsSection,
        sectionToScroll: "",
    });

  return (
    <>
        <ThemeProvider theme={theme}>
          <Helmet>
            {/*<link rel="preconnect" href="https://fonts.googleapis.com" />*/}
            {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />*/}
            {/*<link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />*/}

              {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
              {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
              {/*<link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap" rel="stylesheet"/>*/}

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
              <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet"/>
          </Helmet>

            <BrowserRouter>
                <IsVisibleContext.Provider value={{isVisibleHeader, setIsVisibleHeader}}>
                    <refSections.Provider value={{refSectionsList, setRefSectionsList}}>
                        <TopNavbar />
                        {/*<ModalBase />*/}
                        <Routes>
                            <Route path="/" exact element={<Landing />} />
                            <Route path="/viziune" element={<Viziune />} />
                            <Route path="/misiune" element={<Misiune />} />
                            <Route path="/valori" element={<Valori />} />
                            <Route path="/echipa" element={<Echipa />} />
                            <Route path="/resurse" element={<Resurse />} />
                            <Route path="/lista-de-campanii" element={<ListaCampanii />} />
                            <Route path="/despre-autism" element={<DespreAutism />} />
                            <Route path="/doneaza" element={<Doneaza />} />
                            <Route path="/doneaza-online" element={<DoneazaOnline />} />
                        </Routes>
                        <Footer />
                    </refSections.Provider>
                </IsVisibleContext.Provider>
            </BrowserRouter>
        </ThemeProvider>
      {/*<Landing />*/}
    </>
  );
}

