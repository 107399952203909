import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './FormattedInput.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            maxLength={11}
            thousandSeparator
            valueIsNumericString
            suffix={" RON"}
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function FormattedInputs(props) {
    const { numberformat, setNumberformat } = props;
    const [ values, setValues ] = React.useState({
        numberformat: numberformat,
    });

    const handleChange = (event) => {
        if (event.target.value > 2){
            setNumberformat(event.target.value)
        }
    };

    return (
        <Box
            sx={{
                '& > :not(style)': {
                    m: 1,
                },
                marginBottom: '20px',
            }}
        >
            <TextField
                className={'formattedInputDonationValue'}
                value={numberformat}
                onChange={handleChange}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    // style: { borderRadius: "50px", boxShadow: "10px 13px 38px 0 rgb(0 0 0 / 10%)" }
                }}
                inputProps={{min: 0, style: { textAlign: 'center' }}} // the change is here
                helperText={"Introduceți Suma"}
            />
        </Box>
    );
}