import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

import bgImage from "../../assets/img/numericSection/bg-numeric.png";
import AddImage1 from "../../assets/img/numericSection/1.png";
import AddImage2 from "../../assets/img/numericSection/2.png";
import AddImage3 from "../../assets/img/numericSection/3.png";
import AddImage4 from "../../assets/img/numericSection/4.png";
import AddImageBG1 from "../../assets/img/numericSection/bg1.png";
import AddImageBG2 from "../../assets/img/numericSection/bg2.png";
import AddImageBG3 from "../../assets/img/numericSection/bg3.png";
import AddImageBG4 from "../../assets/img/numericSection/bg4.png";


export default function NumericSection({ text, author }) {
  const [focus, setFocus] = React.useState(false);

  return (
          // backgroundImage: `url(${bgImage})`
      <div className={"lightBg"} style={{backgroundColor: "#fe796a", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "50%", paddingTop: "60px", paddingBottom: "60px"}}>
        <div className={"container"}>
          <div className={"row"} style={{gap: "32px"}}>
            <div className={"col-xs-12 col-sm-4 col-md-2"} style={{...numericBox}}>
              <div style={{...numericBoxImage}}>
                <img style={{...numericBoxImageBg}} src={AddImageBG1} alt=""/>
                <img style={{...numericBoxImageFr}} src={AddImage1} alt="office" />
              </div>
              <CountUp start={focus ? 0 : null} end={7} duration={2} redraw={true}>
                {({ countUpRef }) => (
                    <div>
                      <VisibilitySensor
                          onChange={isVisible => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                      >
                        <h1 ref={countUpRef} />
                      </VisibilitySensor>
                    </div>
                )}
              </CountUp>
              <p>Ani de activitate</p>
            </div>
            <div className={"col-xs-12 col-sm-4 col-md-2"} style={{...numericBox}}>
              <div style={{...numericBoxImage}}>
                <img style={{...numericBoxImageBg}} src={AddImageBG2} alt=""/>
                <img style={{...numericBoxImageFr}} src={AddImage2} alt="office" />
              </div>
              <CountUp start={focus ? 0 : null} end={1720} duration={2} redraw={true}>
                {({ countUpRef }) => (
                    <div>
                      <VisibilitySensor
                          onChange={isVisible => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                      >
                        <h1 ref={countUpRef} />
                      </VisibilitySensor>
                    </div>
                )}
              </CountUp>
              <p>Ore de terapie</p>
            </div>
            <div className={"col-xs-12 col-sm-4 col-md-2"} style={{...numericBox}}>
              <div style={{...numericBoxImage}}>
                <img style={{...numericBoxImageBg}} src={AddImageBG3} alt=""/>
                <img style={{...numericBoxImageFr}} src={AddImage3} alt="office" />
              </div>
              <CountUp start={focus ? 0 : null} end={40} duration={2} redraw={true}>
                {({ countUpRef }) => (
                    <div>
                      <VisibilitySensor
                          onChange={isVisible => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                      >
                        <h1 ref={countUpRef} />
                      </VisibilitySensor>
                    </div>
                )}
              </CountUp>
              <p>Copii trecuți prin centru</p>
            </div>
            <div className={"col-xs-12 col-sm-4 col-md-2"} style={{...numericBox}}>
              <div style={{...numericBoxImage}}>
                <img style={{...numericBoxImageBg}} src={AddImageBG4} alt=""/>
                <img style={{...numericBoxImageFr}} src={AddImage4} alt="office" />
              </div>
              <CountUp start={focus ? 0 : null} end={18} duration={2} redraw={true}>
                {({ countUpRef }) => (
                    <div>
                      <VisibilitySensor
                          onChange={isVisible => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                      >
                        <h1 ref={countUpRef} />
                      </VisibilitySensor>
                    </div>
                )}
              </CountUp>
              <p>Copii beneficiază săptămânal</p>
            </div>
          </div>
        </div>
      </div>
  );
}

const numericBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  color: "white",
  margin: "auto",
  textAlign: "center",
}

const numericBoxImage = {
  position: "relative",
}

const numericBoxImageFr = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)"
}

const numericBoxImageBg = {
  // padding: "20px",
  // borderRadius: "50%",
}
// const QuoteWrapper = styled.div`
//   position: relative;
//   top: -40px;
// `;