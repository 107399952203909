import "./DespreAutism.scss";
import React from "react";
import maintenance from "../assets/img/Server-Maintenance.svg";
import bgheader from "../assets/img/pages/despreAutism/despreAutismHeader.jpg"
// Sections

export default function DespreAutism() {
    return (
        <div id="home" className={'despreAutism'} style={{paddingTop: "80px"}}>
            <div className={'despreAutismHeader'} style={{backgroundImage: `url("${bgheader}")`}}></div>
            <div className="container">
                <h4 className="font30 extraBold">Ce este autismul?</h4>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Tulburarea spectrului autist (TSA) este o tulburare de neurodezvoltare care afectează abilitățile sociale, comunicarea și comportamentul copilului. Mai simplu spus, înseamnă că, creierul unui copil se dezvoltă și funcționează puțin diferit ceea ce poate afecta modul în care un copil înțelege și relaționează cu ceilalți.
                </p>
                <h4 className="font30 extraBold">De ce se numește tulburare din spectrul autist (TSA)?</h4>
                <p className="font16" style={{marginBottom: "16px"}}>
                    Autismul este de fapt un spectru, pentru că toți copiii cu autism sunt toți unici. Unii copii pot avea provocări severe, în timp ce alții nu. Gândindu-ne la afecțiune ca pe un spectru, putem înțelege mai bine gama de funcționare pe care o au persoanele cu autism.
                </p>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Medicii obișnuiau să aibă termeni separați pentru diferite tipuri de autism. De exemplu, sindromul Asperger era un diagnostic pentru unii copii cu autism. Cu toate acestea, în 2013, toate subcategoriile de autism au fost combinate într-un singur diagnostic umbrelă numit tulburare din spectrul autismului.
                </p>
                <h4 className="font30 extraBold">Care sunt semnele sau simptomele autismului?</h4>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Fiecare copil cu autism se va dezvolta diferit. Cel mai frecvent se observă dezinteresul copilului pentru socializare și incapacitatea de a comunica sau de a interacționa cu ceilalți. Printre semnele autismului pot fi menționate și: lipsa contactului vizual, intoleranța la zgomote, crizele de isterie, preferința de a se juca singur, obsesia pentru a ordona lucurile cu care se joacă, mișcările repetate a mâinilor, hiperactivitate sau pasivitate, încapacitatea de a realiza când este într-un pericol.
                </p>
                <h4 className="font30 extraBold">Când se pot vedea semne ale autismului?</h4>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Se pot observa semne de autism <span className={"extraBold"}>înainte ca un copil să împlinească 2 ani</span>, dar cele mai evidente semne și simptome încep să apară între 2 și 3 ani. Ideal ar fi ca autismul să fie identificat cât mai precoce, adică, cât mai devreme. Vă recomandăm aplicarea unui chestionar (screening) aprobat în 2014 de Ministerul Sănătății. <span className={"extraBold"}>Chestionarul poate fi accesat la <a href={'/resurse'}>Resurse</a></span> (link)
                </p>
            </div>
        </div>
    );
}