import React, {useEffect, useState} from "react";
import "./Campaign.scss";

import ProjectImg4 from "../../../assets/img/serviciiOferite/logopedia.jpg";
import LinearProgressBar from "../../../components/LinearProgress/LinearProgress";
import Button from "@mui/material/Button";

interface State {

}

export default function Campaign(props:State) {
    const [moneyDonated, setMoneyDonated] = useState(1550);
    const [moneyNeeded, setMoneyNeeded] = useState(3000);
    const [percentCompleted, setPercentCompleted] = useState();

    // Calculate percentCompleted when moneyDonated or moneyNeeded changes
    useEffect(() => {
        const calculatedPercent = (moneyDonated / moneyNeeded) * 100;
        setPercentCompleted(calculatedPercent);
        console.log(percentCompleted);
    }, [moneyDonated, moneyNeeded]);

    return (
        <div className={"row-campaign row py-3 mb-5"}>
            <div className={"col-xs-4 d-flex"}>
                <img width={"100%"} src={ProjectImg4} alt="campanie1"/>
            </div>
            <div className={"col-xs-4 d-flex align-items-center justify-content-center"}>
                <div>
                    <h5 className={"mb-2"}>O mână iscusită – proiect cu fam. Mateescu (jud. Timis)</h5>
                    <p>Despre acest minunat Creator trebuie să cunoască fiecare copil. Iscusința și priceperea Lui trebuie cunoscute de fiecare copil din Timiș… citeşte mai mult</p>
                </div>
            </div>
            <div className={"col-xs-4 d-flex flex-column gap-3 align-items-center justify-content-around"}>
                <div className={'w-100'}>
                    <LinearProgressBar valueBar={percentCompleted}></LinearProgressBar>
                    <h6 className={'text-center text-lowercase mt-1'}>24 ZILE RĂMASE</h6>
                </div>
                <div className={'d-flex justify-content-around align-items-center w-100 gap-3'}>
                    <div className={'text-center'}>
                        <p className={'fw-bold'}>PÂNĂ ACUM</p>
                        <h3 className={'fw-bold text-success'}>{moneyDonated.toLocaleString()} RON</h3>
                    </div>
                    <hr style={{height: "100%", borderLeft: "2px solid rgb(0 0 0)"}}></hr>
                    <div className={'text-center'}>
                        <p className={'fw-bold'}>SCOP</p>
                        <h3 className={'fw-bold'}>{moneyNeeded.toLocaleString()} RON</h3>
                    </div>
                </div>
                <Button variant="outlined" size="large" color="ochre">
                    VREAU SĂ AJUT
                </Button>
            </div>
        </div>
    );
}


