import React, {useContext, useEffect, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import "./header.scss";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/pavol-stugel.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import {IsVisibleContext, refSections} from "../../App";
import ModalVideo from "../ModalVideo/ModalVideo";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import ReactPlayer from "react-player";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {Button} from "@mui/material";


export default function Header() {

    const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
    const { height, width } = useWindowDimensions();
    const { refSectionsList, setRefSectionsList } = useContext(refSections);

  return (
      <div className={"gosenHomeHeader"} style={{
          position: "relative",
      }}>
          <div className={"bgVideoContainer"}>
              {
                  width > 768 && (
                    <>
                        <iframe
                            className={"bgVideo"}
                            width="720" height="405"
                            src={"https://www.youtube.com/embed/m-2l-w80hmg?autoplay=1&loop=1&showinfo=0&color=white&playlist=m-2l-w80hmg&mute=1&controls=0"}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                        <div className={"bgCoverColor"}></div>
                    </>
                  )
              }
          </div>

      <Wrapper id="home" ref={refSectionsList.homeSection} className="container flexSpaceCenter">
          <LeftSide className="flexCenter">

              <Fade in={isVisibleHeader} timeout={1500} >
                  <div>
                      <h1 style={width > 768 ? {
                          color: "white",
                          marginBottom: "60px",
                      } : {marginBottom: "60px"}} className="extraBold font60">Împreună luptăm pentru o viață mai bună</h1>
                      {
                          width > 768 ? <ModalVideo /> : (
                              <>
                                  <Button style={{
                                      marginBottom: "64px",
                                      backgroundColor: "#FF0000",
                                      padding: "12px 26px",
                                      fontSize: "16px",
                                  }} startIcon={<CreditCardIcon />} color={'warning'} variant="contained" size="large"
                                          type={"default"} onClick={() => {window.open("https://donate.stripe.com/aEUfZe5dJb6z1H27ss", "_blank");}}>
                                      Donează acum
                                  </Button>
                                  <ReactPlayer
                                      className={"mobileVideo"}
                                      style={{
                                          borderRadius: "14px",
                                          maxWidth: "100%",
                                      }}
                                      url={"https://www.youtube.com/embed/m-2l-w80hmg"}
                                      width={"100%"}
                                      height={"300px"}
                                      controls={true}
                                  />
                              </>
                          )
                      }
                      {/*<HeaderP className="font13 semiBold">*/}
                      {/*    Centrul de Zi și Recuperare pentru copii cu dizabilități Gosen Arad*/}
                      {/*</HeaderP>*/}
                      {/*<BtnWrapper>*/}
                      {/*    <FullButton action={() => {window.open("https://forms.gle/GxQNCtM2zwFkRvqz8", "_blank");}} title="Servici gratuite fă o programare" />*/}
                      {/*</BtnWrapper>*/}
                  </div>
              </Fade>
          </LeftSide>
          {/*<RightSide>*/}
          {/*    <Zoom in={isVisibleHeader} timeout={1000}>*/}
          {/*        <ImageWrapper>*/}
          {/*          /!*<Img className="radius8 headerImage" src={HeaderImage} alt="office" style={{zIndex: 9}} />*!/*/}
          {/*          <QuoteWrapper className="flexCenter darkBg radius8">*/}
          {/*            <QuotesWrapper>*/}
          {/*              <QuotesIcon />*/}
          {/*            </QuotesWrapper>*/}
          {/*            <div>*/}
          {/*              <p className="font16 whiteColor">*/}
          {/*                <em>„ Nu există un handicap mai mare al socității decât începacitatea de a vedea mai mult într-o persoană ”</em>*/}
          {/*              </p>*/}
          {/*              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Robert M. Hensel</p>*/}
          {/*            </div>*/}
          {/*          </QuoteWrapper>*/}
          {/*          <DotsWrapper>*/}
          {/*            <Dots />*/}
          {/*          </DotsWrapper>*/}
          {/*        </ImageWrapper>*/}
          {/*      </Zoom>*/}
          {/*  <GreyDiv className="lightBg"></GreyDiv>*/}
          {/*</RightSide>*/}
      </Wrapper>
  </div>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  z-index: 2;
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 80%;
  height: 100%;
  text-align: center;
  z-index: 2;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 60px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


