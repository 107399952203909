import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ReactPlayer from "react-player";
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {useState} from "react";
import "./ModalVideo.scss";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};

export default function KeepMountedModal() {
    const [isOpenVideo, setIsOpenVideo] = useState(false);

    const onOpenModal = () => {
        setIsOpenVideo(!isOpenVideo);
    };

    return (
        <div>
            <Button style={{
                padding: "12px 26px",
                backgroundColor: "#f2b300",
                fontSize: "16px",
            }} startIcon={<PlayArrowIcon />} color={'error'} variant="contained" size="large" type={"default"} onClick={onOpenModal}>Video</Button>
            <Modal
                keepMounted
                open={isOpenVideo}
                onClose={onOpenModal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box className={"modalVideoModalBox"} sx={style}>
                    <ReactPlayer
                        className={"modalVideo"}
                        style={{
                            borderRadius: "14px",
                            maxWidth: "100%",
                        }}
                        url={"https://www.youtube.com/embed/m-2l-w80hmg"}
                        width={"100%"}
                        height={"100%"}
                        controls={true}
                        playing={isOpenVideo}
                    />
                    {/*<iframe*/}
                    {/*    className={"modalVideo"}*/}
                    {/*    src={"https://www.youtube.com/embed/m-2l-w80hmg?rel=0"}*/}
                    {/*    frameBorder="0"*/}
                    {/*    width={"100%"}*/}
                    {/*    height={"100%"}*/}
                    {/*    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                    {/*    allowFullScreen*/}
                    {/*    style={{zIndex: 9}}*/}
                    {/*    title="Embedded youtube"*/}
                    {/*/>*/}
                </Box>
            </Modal>
        </div>
    );
}