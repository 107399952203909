import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";

import MissionIcon from "../../assets/svg/Services/Mission";
import TeamIcon from "../../assets/svg/Services/Team";
import ValuesIcon from "../../assets/svg/Services/Values";
import VisionIcon from "../../assets/svg/Services/Vision";

export default function ServiceBox({icon, image, title, subtitle, listItems, style}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    case "mission":
      getIcon = <MissionIcon />;
      break;
    case "team":
      getIcon = <TeamIcon />;
      break;
    case "values":
      getIcon = <ValuesIcon />;
      break;
    case "vision":
      getIcon = <VisionIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn" style={{ justifyContent: "center", alignItems: "center", textAlign: "center", ...style}}>
      {icon && <IconStyle>{getIcon}</IconStyle>}
      {image && <img width={"75px"} height={"75px"} src={image}/>}
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      {subtitle && (<SubtitleStyle className="font13">{subtitle}</SubtitleStyle>)}
      {
        listItems &&
          (
              <ul>
                {listItems.map((listItem, index) => <li key={index} className={"font13"} style={{...serviceBoxli}}>{listItem}</li>)}
              </ul>
          )
      }
    </Wrapper>
  );
}

const serviceBoxli = {
  // fontWeight: "600",
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 16px 0;
  color: #e51e25;
  @media (max-width: 860px) {
    padding: 16px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;