import React from "react";
import "./Doneaza.scss";
// Sections
import bgheader from "../assets/img/pages/donation/joel_overbeck.jpg";
import StripeForm from "../components/StripeForm/StripeForm";


export default function DoneazaOnline() {
    return (
        <div id="home" className={'doneazaContent'} style={{paddingTop: "80px"}}>
            <div className={'despreAutismHeader'} style={{backgroundImage: `url("${bgheader}")`}}></div>
            <div className="container">
                <StripeForm/>
            </div>
        </div>
    );
}


