import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";
import "./Contact.scss";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import bgContact from "../../assets/img/new/bg-register.png";
import {refSections} from "../../App";

export default function Contact() {
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");
  const { refSectionsList, setRefSectionsList } = useContext(refSections);

  // const handleSend = async() => {
  //   setSent(true);
  //   try {
  //     await axios.post("https://centrulgosen.com/api/stripeConnection", {
  //       message: 'test',
  //     })
  //   } catch (error) {
  //     console.log(error);
  //
  //   }
  // }

  const handleSend = async() => {
    let item = {"test": "test", "test1": "test1", "email": "email"};

    let result = await fetch("https://centrulgosen.com/api/stripeConnection",{
      method: 'POST',
      body: JSON.stringify(item),
      // headers: {
      //   "Content-Type": "application/json",
      //   "Accept": "application/json",
      // }
    })
    console.log(result);
  }

  // useEffect(()=>{
  //   handleSend().then(r => console.log(r));
  // }, [])

  return (
    <Wrapper id="contact" ref={refSectionsList.contactSection}>
      <div>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{marginBottom: "24px"}}>Contact</h1>
            <p className="font16 semiBold">
              Centrul Gosen Arad
            </p>
            <p className="font16 semiBold">
              Calea Aurel Vlaicu, Nr 121-125, et 3
            </p>
            <p className="font16 semiBold">
              Interfon 03
            </p>
            <p className="font16 semiBold">
              Email: <a href={"mailto:asociatiagosen@gmail.com"}>asociatiagosen@gmail.com</a>
            </p>
            <p className="font16 semiBold">
              Asistent Social: Sergiu Culda
            </p>
            <p className="font16 semiBold">
              Telefon: <a href={"tel:0756930650"}>0756 930 650</a>
            </p>
          </HeaderInfo>
          {/*<div className="row" style={{ paddingBottom: "30px" }}>*/}
            {/*<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{margin: "auto"}}>*/}
            {/*  <Form onSubmit={handleSend}>*/}
            {/*    <label className="font13">First name:</label>*/}
            {/*    <input type="text" id="fname" name="fname" className="contactInput font20 extraBold" />*/}
            {/*    <label className="font13">Email:</label>*/}
            {/*    <input type="text" id="email" name="email" className="contactInput font20 extraBold" />*/}
            {/*    <label className="font13">Subject:</label>*/}
            {/*    <input type="text" id="subject" name="subject" className="contactInput font20 extraBold" />*/}
            {/*    <textarea rows="4" cols="50" type="text" value={message} id="message" name="message" className="contactInput font20 extraBold" onChange={(e) => setMessage(e.target.value)} />*/}
            {/*    <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} />*/}
            {/*  </Form>*/}
            {/*  /!*<SumbitWrapper className="flex">*!/*/}
            {/*  /!*  <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} />*!/*/}
            {/*  /!*</SumbitWrapper>*!/*/}
            {/*  {*/}
            {/*    sent ?? (*/}
            {/*      <h1>Email Sent</h1>*/}
            {/*    )*/}
            {/*  }*/}
            {/*</div>*/}
            {/*<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex" style={{margin: "auto"}}>*/}
            {/*  <div style={{ width: "50%" }} className="flexNullCenter flexColumn">*/}
            {/*    <ContactImgBox>*/}
            {/*      <img src={ContactImg1} alt="office" className="radius6" />*/}
            {/*    </ContactImgBox>*/}
            {/*    <ContactImgBox>*/}
            {/*      <img src={ContactImg2} alt="office" className="radius6" />*/}
            {/*    </ContactImgBox>*/}
            {/*  </div>*/}
            {/*  <div style={{ width: "50%" }}>*/}
            {/*    <div style={{ marginTop: "100px" }}>*/}
            {/*      <img src={ContactImg3} alt="office" className="radius6" />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          {/*</div>*/}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 320px;
  background: url(${bgContact});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
`;
const HeaderInfo = styled.div`
  padding: 16px 0 100px 0;
  text-align: center;
    p{
      color: #4d4d55;
      margin-bottom: 8px;
    }
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  .contactInput {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #f2b300;
  background-color: #f2b300;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #f66;
    border: 1px solid #f2b300;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









