import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import "./TopNavbar.scss";
import { Link } from "react-scroll";
import { Link as LinkRouter, NavLink, useLocation } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import {Button} from "@mui/material";
import logo from "../../assets/img/logo.svg";
import {IsVisibleContext, refSections} from "../../App";
import VisibilitySensor from "react-visibility-sensor";
import ScrollIntoView from 'react-scroll-into-view'

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(false);
  const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
  const { refSectionsList, setRefSectionsList } = useContext(refSections);

  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  useEffect(() => {
      if (refSectionsList.sectionToScroll === "despre-noi"){
          refSectionsList.aboutSection.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }

      if (refSectionsList.sectionToScroll === "contact"){
          refSectionsList.contactSection.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }

      if (refSectionsList.sectionToScroll === "projects"){
          refSectionsList.projectsSection.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }

      if (refSectionsList.sectionToScroll === "home"){
          refSectionsList.homeSection.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate" style={y > 100 ? { height: "60px", backgroundColor: "rgb(255 255 255)", boxShadow: "0 1px 4px rgb(4 5 6 / 8%), 0 2px 4px rgb(4 5 6 / 10%), 0 4px 8px rgb(4 5 6 / 4%)" } : { height: "80px", backgroundColor: "rgb(255 255 255 / 77%)", boxShadow: "0 1px 4px rgb(4 5 6 / 8%), 0 2px 4px rgb(4 5 6 / 10%), 0 4px 8px rgb(4 5 6 / 4%)" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home">
            {/*<LogoIcon />*/}
              <VisibilitySensor
                  onChange={isVisible => {
                      if (isVisible) {
                          setIsVisibleHeader(true);
                      }
                  }}>
                <img src={logo} alt={logo} width={"45px"} height={"45px"}/>
              </VisibilitySensor>
              <LinkRouter to={"/"}>
                <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                  Centrul Gosen
                </h1>
              </LinkRouter>
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font16 pointer">
                {
                    location.pathname === "/" ?
                        <Link activeClass={"active"} style={{ padding: "10px 15px" }} to="home" spy={true} offset={-80}>
                            <LinkRouter to={"/"}>Home</LinkRouter>
                        </Link>
                        :<LinkRouter onClick={() => {if (location.pathname !== "/") { refSectionsList.sectionToScroll = "home" }}} style={{ padding: "10px 15px" }} to={"/"}>Home</LinkRouter>
                }
            </li>
            <li className="semiBold font16 pointer relative">
                {
                    location.pathname === "/" ?
                        <Link activeClass="active" className={"dropdown"} to="despre-noi"
                              spy={true} offset={-80}
                              style={{padding: "10px 15px"}}
                              onMouseEnter={handleClick}
                              onMouseLeave={handleClose}
                        >
                            <LinkRouter
                                to={"/"}>Despre noi
                            </LinkRouter>
                        </Link>
                        : <LinkRouter
                            onClick={() => {if (location.pathname !== "/") { refSectionsList.sectionToScroll = "despre-noi" }}}
                            className={"dropdown"} onMouseEnter={handleClick} onMouseLeave={handleClose} to={"/"}
                            style={{padding: "10px 15px"}}>Despre noi
                        </LinkRouter>
                }
              <div
                  className={"gosen-dropdown"} style={anchorEl ? gosen_dropdown_active : {}}
                  // ref={anchorEl}
                  onMouseEnter={handleClick}
                  onMouseLeave={handleClose}
              >
                  <NavLink to={"viziune"}>Viziune</NavLink>
                  <NavLink to={"misiune"}>Misiune</NavLink>
                  <NavLink to={"valori"}>Valori</NavLink>
                  <NavLink to={"echipa"}>Echipa</NavLink>
                  {/*<NavLink to={"lista-de-campanii"}>Lista De Campanii</NavLink>*/}
              </div>
            </li>
            <li className="semiBold font16 pointer">
                {
                    location.pathname === "/" ?
                        <Link activeClass="active" style={{padding: "10px 15px"}} to="projects" spy={true} offset={-60}>
                            <LinkRouter to={"/"}>Servicii oferite</LinkRouter>
                        </Link>
                        : <LinkRouter
                            onClick={() => {if (location.pathname !== "/") refSectionsList.sectionToScroll = "projects";}} style={{padding: "10px 15px"}} to={"/"}>Servicii oferite</LinkRouter>
                }
            </li>
            <li className="semiBold font16 pointer">
              <NavLink style={{ padding: "10px 15px" }} to={"resurse"} onClick={() => {window.scrollTo(0, 0)}}>Resurse</NavLink>
            </li>
            <li className="semiBold font16 pointer">
                  <NavLink style={{ padding: "10px 15px" }} to={"/despre-autism"} onClick={() => {window.scrollTo(0, 0)}}>Despre autism</NavLink>
            </li>
            <li className="semiBold font16 pointer">
                {
                    location.pathname === "/" ?
                        <Link activeClass={"active"} style={{padding: "10px 15px"}} to="contact" spy={true} offset={-60}>
                            <LinkRouter to={"/"}>
                                Contact
                            </LinkRouter>
                        </Link>
                        : <LinkRouter
                            onClick={() => {if (location.pathname !== "/") refSectionsList.sectionToScroll = "contact"}} style={{padding: "10px 15px"}} to="/">
                            Contact
                        </LinkRouter>
                }
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font16 pointer">
                  <NavLink to={"/doneaza"} onClick={() => {window.scrollTo(0, 0)}}>
                    <Button variant="contained" style={doneazaCTA} color="error" size="large">
                        Doneaza
                    </Button>
                  </NavLink>
              </li>
            {/*<li className="semiBold font16 pointer">*/}
            {/*  <a href="/" style={{ padding: "10px 30px 10px 0" }}>*/}
            {/*    Log in*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li className="semiBold font16 pointer flexCenter">*/}
            {/*  <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>*/}
            {/*    Get Started*/}
            {/*  </a>*/}
            {/*</li>*/}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const doneazaCTA = {
    // position: "absolute",
    // top: "66px",
    // right: "16px",
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const gosen_dropdown_active = {
    opacity: 1,
    zIndex: 1,
    visibility: "visible",
}


