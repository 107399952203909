import React, {useState} from "react";
import "./Resurse.scss";
import maintenance from "../assets/img/Server-Maintenance.svg";
import chestionar from "../assets/pdf/chestionar_identificare_simptome_autism.pdf"
import bgheader from "../assets/img/pages/resurse/resources_written_in_search_bar_on_virtual_screen.jpeg";
import {Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// Sections

// interface State {
//     amount: string;
//     password: string;
//     weight: string;
//     weightRange: string;
//     showPassword: boolean;
// }

export default function Resurse(props) {

    const [pwd, setPwd] = useState("");
    const [success, setSuccess] = useState(true);
    // const [values, setValues] = React.useState<State>({
    //     amount: '',
    //     password: '',
    //     weight: '',
    //     weightRange: '',
    //     showPassword: false,
    // });
    //
    //
    // const handleClickShowPassword = () => {
    //     setValues({
    //         ...values,
    //         showPassword: !values.showPassword,
    //     });
    // };
    //
    // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     event.preventDefault();
    // };

    return (
        <div id="home" className={'paginaResurse'} style={{paddingTop: "80px"}}>
            <div className={'paginaResurseHeader'} style={{backgroundImage: `url("${bgheader}")`}}></div>
            <div className="container">
                <h4 className="font30 extraBold" style={{marginBottom: "8px"}}>Screening - Program Național de Identificare Precoce a Tulburării de Spectru Autist și Tulburări Asociate</h4>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Acest chestionar permite identificarea primelor simptome ale unei tulburări de spectru autist, menite să îndrume părintele către un specialist.
                </p>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Acest screening are <span className={"purpleColor"}>9 întrebări</span>, se aplică gratuit de către medicul de familie, durează între 5 și 10 minute și poate ajuta părintele să-și dea seama foarte devreme dacă copilul poate să dezvolte acest tip de întârziere sau a dezvoltat deja. Dacă se obţine un scor peste 10, scor care indică că putem vorbi de TSA, medicul de familie trimite copilul mai departe către medicul psihiatru.
                </p>
                <a href={chestionar} target="_blank">
                    <Button variant="contained"  color="primary" size="large" style={{marginBottom: "80px"}}>
                        Chestionar
                    </Button>
                </a>
                <h4 className="font30 extraBold" style={{marginBottom: "8px"}}>Resurse pentru părinți</h4>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Va rugam sa introduceti parola pentru a accesa resursele:
                </p>

                <FormControl sx={{ width: '25ch' }} variant="outlined" style={{display: "block", marginBottom: "24px"}}>
                    {/*<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>*/}
                    <TextField
                        id="outlined-adornment-password"
                        type={'password'}
                        onChange={(e) => {
                            // console.log(pwd, success); setPwd(e.target.value);
                            if (e.target.value === "resursegratuitegosen") {
                                setPwd(e.target.value);
                                setSuccess(true);
                            } else {
                                setPwd("");
                                setSuccess(false);
                            }
                        }}
                        label="Parolă"

                        error={!success && true}
                        helperText={!success && "Parolă Incorectă"}
                    />
                </FormControl>

                <Button variant="contained"  color="primary" size="large" style={{marginBottom: "32px"}}
                        onClick={() => {
                            if (success && pwd !== ""){
                                window.open("https://drive.google.com/drive/folders/1OMvx4rKvERum1-zXbyUO8_cGkJ-L4HUC?usp=sharing", "_blank")
                            }
                        }}
                >
                    Acceseaza Resurse
                </Button>

            </div>
        </div>
    );
}


